/*
Taylor Tobin's Reset -- covers resets not included in Meyer Reset
*/

/* $box-sizing - https://www.paulirish.com/2012/box-sizing-border-box-ftw/
    apply a natural box layout model to all elements, but allowing components to change
*/
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  cursor: default;
}

#root {
  width: 100%;
}

/* // almost ALL links are actually big images or blocks of text - so this is a reasonable default */
a {
  display: block;
  line-height: normal;
  text-decoration: none;
  color: inherit;
}

article p a {
  display: initial;
  text-decoration: initial;
  color: initial;
}

img {
  user-select: none;
}

svg * {
  fill: inherit;
}

figure svg {
  display: block;
  width: 100%;
  height: 100%;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

input {
  border: 1px solid #f4f4f4;
}

/* think about this - in regards to accessibility */
input:focus {
  outline: none;
}

/* remove mobile safari touch highlighting / (replace with your own using active) */
* {
  -webkit-tap-highlight-color: transparent; /* for remove highlight */
}

/* note: textarea and input[type="text"] already receive "cursor: text" via browsers' base stylesheets */
a,
label,
button,
input[type="radio"],
input[type="submit"],
input[type="button"],
input[type="checkbox"] {
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"][readonly] {
  pointer-events: none;
}

/* Reset `button` and button-style `input` default styles */
input[type="submit"],
input[type="reset"],
input[type="button"],
button {
  background: none;
  border: 0;
  color: inherit;
  display: block;
  width: 100%;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  margin: 0;
  cursor: pointer;

  -webkit-appearance: button; /* for input */
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus,
button:focus {
  outline: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

/** ACCESSIBILITY **/
/* // https://a11yproject.com/posts/how-to-hide-content/ */
.visually-hidden {
  /* https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

abbr {
  text-decoration: none;
}
